<template>
  <div :class="`vacciland ${getCurrentLangs.lang}`" data-view>
    <Header />
    <scrollAnalytics></scrollAnalytics>


    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('vacciland.head')"></div>

        <router-link to="/cases" class="top__back-btn cursor-hover">
          <div class="top__arrow">
            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071" stroke="black" stroke-linecap="round" />
              <path d="M15.2988 29.973L38.2398 29.9732" stroke="black" stroke-linecap="round" />
            </svg>
          </div>

          {{ $t("back-btn") }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper" v-for="(key, value) in $t('vacciland.title')" :key="value">
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class="banner">
      <picture>
        <source srcset="@/assets/img/cases/vacciland/banner-mob.jpg" media="(max-width: 425px)" />
        <source srcset="@/assets/img/cases/vacciland/banner-tablet.jpg" media="(max-width: 768px)" />
        <img src="@/assets/img/cases/vacciland/banner.jpg" alt="" />
      </picture>
    </section>

    <section class="section-text">
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('vacciland.challenge.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-text" v-html="$t('vacciland.challenge.right-text')"></p>
          <p class="text-row__right-title" v-html="$t('vacciland.challenge.right-title')"></p>
          <p class="text-row__right-text text-row__right-text_smb" v-html="$t('vacciland.challenge.right-text2')"></p>
          <p class="text-row__right-text" v-html="$t('vacciland.challenge.right-text3')"></p>
        </div>
      </div>
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('vacciland.solution.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('vacciland.solution.right-title')"></p>
          <p class="text-row__right-text" v-html="$t('vacciland.solution.right-text')"></p>
        </div>
      </div>
      <div class="img-solution">
        <img v-parallax.modifier.absY="-0.06" src="@/assets/img/cases/vacciland/solution-1.png" class="img-solution_1" alt="" />
        <img v-parallax.modifier.absY="0.06" src="@/assets/img/cases/vacciland/solution-2.png" class="img-solution_2" alt="" />
        <img v-parallax.modifier.absY="-0.06" src="@/assets/img/cases/vacciland/solution-3.png" class="img-solution_3" alt="" />
      </div>
      <div class="text-row approach">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('vacciland.approach.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('vacciland.approach.right-title')"></p>
          <p class="text-row__right-text" v-html="$t('vacciland.approach.right-text')"></p>
          <p class="text-row__right-note" v-html="$t('vacciland.approach.right-note')"></p>
        </div>
      </div>
      <div class="img-approach">
        <picture>
          <source srcset="@/assets/img/cases/vacciland/approach-bg-mob.png" media="(max-width: 425px)" />
          <source srcset="@/assets/img/cases/vacciland/approach-bg-tablet.png" media="(max-width: 768px)" />
          <img src="@/assets/img/cases/vacciland/approach-bg.png" class="img-approach__bg" alt="" />
        </picture>
        <img v-parallax.modifier.absY="0.05" src="@/assets/img/cases/vacciland/approach.png" class="img-approach__center" alt="" />
      </div>
    </section>

    <div class="bg-illustrations">
      <section class="illustrations">
        <div class="illustrations__row illustrations__row_title">
          <div class="illustrations__col illustrations__col_left">
            <p class="illustrations__title" v-html="$t('vacciland.illustrations.left-title')"></p>
          </div>
          <div class="illustrations__col illustrations__col_right">
            <p class="illustrations__title" v-html="$t('vacciland.illustrations.right-title')"></p>
          </div>
        </div>
        <div class="illustrations__row illustrations__row_bottom">
          <div class="illustrations__col illustrations__col_left">
            <div class="illustrations__img" v-parallax.modifier.absY="0.01">
              <img src="@/assets/img/cases/vacciland/illustration-1.jpg" alt="" />
              <img src="@/assets/img/cases/vacciland/illustration-2.jpg" class="illustrations-bottom" alt="" />
            </div>
          </div>
          <div class="illustrations__col illustrations__col_right">
            <div class="illustrations__img" v-parallax.modifier.absY="-0.08">
              <img src="@/assets/img/cases/vacciland/illustration-3.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="illustrations__row illustrations__row_title">
          <div class="illustrations__col illustrations__col_left"></div>
          <div class="illustrations__col illustrations__col_right">
            <p class="illustrations__title" v-html="$t('vacciland.illustrations.right-title2')"></p>
          </div>
        </div>
        <div class="illustrations__row illustrations__row_bottom">
          <div class="illustrations__col illustrations__col_left">
            <div class="illustrations__img">
              <img src="@/assets/img/cases/vacciland/illustration-4.jpg" alt="" />
              <img src="@/assets/img/cases/vacciland/illustration-5.jpg" alt="" />
            </div>
          </div>
          <div class="illustrations__col illustrations__col_right">
            <div class="illustrations__img" v-parallax.modifier.absY="-0.05">
              <img src="@/assets/img/cases/vacciland/illustration-6.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="illustrations__row illustrations__row_reverse">
          <div class="illustrations__col illustrations__col_left">
            <div class="illustrations__img">
              <img src="@/assets/img/cases/vacciland/illustration-7.jpg" alt="" />
            </div>
          </div>
          <div class="illustrations__col illustrations__col_right">
            <div class="illustrations__img" v-parallax.modifier.absY="-0.01">
              <img src="@/assets/img/cases/vacciland/illustration-8.jpg" alt="" />
              <img src="@/assets/img/cases/vacciland/illustration-9.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="section-text section-video">
      <div class="video">
        <div class="video__title" v-html="$t('vacciland.video.title')"></div>
        <div class="video__wrapper">
          <div class="video__play-btn cursor-hover" @click="playVideo" v-bind:class="[{ active: !videoTrigger }]"></div>
          <video v-if="getCurrentLangs.lang === 'en'" @click="playVideo" ref="video" poster="../../assets/img/cases/vacciland/video-poster-1.jpg">
            <source src="@/assets/video/vacciland-en.mp4" type="video/mp4" />
          </video>
          <video v-else @click="playVideo" ref="video" poster="../../assets/img/cases/vacciland/video-poster-1.jpg">
            <source src="@/assets/video/vacciland-ua.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="video video-iframe">
        <div class="video__title" v-html="$t('vacciland.video.title2')"></div>
        <div class="video__wrapper">
          <div class="video__play-btn cursor-hover" @click="playVideo2" v-bind:class="[{ active: !videoTrigger2 }]"></div>
          <div v-if="!videoTrigger2" class="video__poster" @click="playVideo2"></div>
          <youtube v-if="getCurrentLangs.lang === 'en'" :video-id="videoId" :player-vars="playerVars" ref="youtube" @playing="playing"></youtube>
          <youtube v-else :video-id="videoId2" :player-vars="playerVars" ref="youtube" @playing="playing"></youtube>
        </div>
      </div>

      <div class="text-row text-row_bottom text-application">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('vacciland.application.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('vacciland.application.right-title')"></p>
          <p class="text-row__right-text" v-html="$t('vacciland.application.right-text')"></p>
        </div>
      </div>
      <section class="cards">
        <picture>
          <img src="@/assets/img/cases/vacciland/cards.png" v-parallax.modifier.absY="-0.1" alt="" />
        </picture>
      </section>
    </section>

    <div class="bg-landing">
      <section class="landing">
        <div class="text-row text-landing">
          <div class="text-row__left">
            <p class="text-row__left-text" v-html="$t('vacciland.landing.left-title')"></p>
          </div>
          <div class="text-row__right">
            <p class="text-row__right-title" v-html="$t('vacciland.landing.right-title')"></p>
            <p class="text-row__right-text" v-html="$t('vacciland.landing.right-text')"></p>
          </div>
        </div>
        <picture>
          <source srcset="@/assets/img/cases/vacciland/landing-mob.png" media="(max-width: 425px)" />
          <source srcset="@/assets/img/cases/vacciland/landing-tablet.png" media="(max-width: 768px)" />
          <img src="@/assets/img/cases/vacciland/landing.png" alt="" />
        </picture>
      </section>
    </div>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import similar from "@/components/similar.vue";
import Footer from "@/components/NewFooter.vue";
import anime from "animejs";
import { mapGetters, mapMutations } from "vuex";
// import getElementVisible from '@/helper';

export default {
  name: "Vacciland",
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      videoTrigger2: false,
      videoId: "EKZf1t0JeXM",
      videoId2: "OK-cd5-BIzo",
      playerVars: {
        autoplay: 0,
        html5: 1,
      },
      similarData: {
        title: "similar-title-type1",
        prevLink: "/cases/shift-work",
        nextLink: "/cases/happy-liver",
        linkShow: true,
        slider: ["slider-happy-liver", "slider-no-spa", "slider-allegra", "slider-listerin"],
      },
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 30,

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
      },
      currentPosesSlide: 1,
      currentSlide: 0,
    };
  },

  mounted() {
    this.setCurrentPage("cases");

    setTimeout(() => {
      const whySection = document.querySelector(".top");
      const textWrapperAll = whySection.querySelectorAll(".text-wrapper .letters");
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes("®")) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>").replace("®", "<sup>®</sup>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({ loop: false }).add({
          targets: ".text-wrapper .letter",
          translateY: ["1.2em", 0],
          translateZ: 0,
          duration: 750,
          delay: (el, i) => 50 * i,
        });
      });
    }, 20);
  },

  methods: {
    ...mapMutations(["setCurrentPage"]),
    playVideo() {
      const mediaVideo = this.$refs.video;
      if (mediaVideo.paused) {
        mediaVideo.play();
        this.videoTrigger = true;
        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
      }
    },

    playVideo2() {
      if (!this.videoTrigger2) {
        this.player.playVideo();
        this.videoTrigger2 = true;
      } else {
        this.player.stopVideo();
        this.videoTrigger2 = false;
      }
    },
    playing() {
      // console.log("\o/ we are watching!!!");
    },
  },

  computed: {
    ...mapGetters(["getCurrentLangs"]),
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style scoped lang="scss">
.vacciland {
  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  &.ua {
  }

  .parallax {
    overflow: hidden;
  }

  .top {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 43px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }
    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all 0.2s ease;

      @media screen and (min-width: 1023px) {
        &:hover {
          svg {
            path {
              stroke: #29f6d9;
            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29f6d9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all 0.2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 108px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;
      letter-spacing: 1.1px;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 0.9em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .text-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 161px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 113px;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 93px;
    }
    &_bottom {
      margin-bottom: 88px;
      @media screen and (max-width: 1023px) {
        margin-bottom: 55px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 35px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 23px;
      }
      @media screen and (max-width: 1023px) and (min-width: 768px) {
        min-width: 200px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;
      @media screen and (max-width: 1023px) and (min-width: 768px) {
        margin-left: 30px;
      }

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 4px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 18px;
      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }
      &_smb {
        margin-bottom: 8px;
        @media screen and (max-width: 600px) {
          margin-bottom: 8px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
      & + .text-row__right-title {
        @media screen and (max-width: 600px) {
          margin-top: 24px;
        }
      }
    }

    &__right-note {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: var(--case-allegra-color-top);
      margin-bottom: 18px;
      color: #92a1ae;
      margin-top: -10px;
      @media screen and (max-width: 600px) {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .section-text {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 0px 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 0px 45px;
    }

    @media screen and (max-width: 600px) {
      padding: 47px 20px 0px 20px;
    }

    &__double-block {
    }

    &__video-wrapper {
      margin-top: 64px;
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .img-solution {
    position: relative;
    margin: -38px 0 119px;
    display: block;
    @media screen and (max-width: 1023px) {
      margin: -6px 0 117px;
    }
    @media screen and (max-width: 767px) {
      margin: -85px 0 87px;
    }
    img {
      display: block;
      max-width: 100%;
    }
    &_1 {
      position: absolute;
      left: 0;
      top: -131px;
      @media screen and (max-width: 1023px) {
        width: 243px;
        top: -103px;
      }
      @media screen and (max-width: 767px) {
        position: relative;
        top: 0;
        width: 205px;
      }
    }

    &_2 {
      position: relative;
      margin: 0 auto;
      left: -5.5%;
      @media screen and (max-width: 1023px) {
        width: 355px;
      }
      @media screen and (max-width: 767px) {
        width: 308px;
        left: auto;
        right: -20px;
        margin: -50px 0 -50px auto;
      }
    }

    &_3 {
      position: absolute;
      right: 0;
      top: -4px;
      @media screen and (max-width: 1023px) {
        width: 256px;
        top: -69px;
      }
      @media screen and (max-width: 767px) {
        position: relative;
        top: 0;
        width: 220px;
      }
    }
  }

  .approach {
    margin-bottom: 5px;
    .text-row__right-title {
      margin-bottom: 22px;
    }
  }
  .text-application {
    .text-row__right-title {
      margin-bottom: 29px;
      @media screen and (max-width: 1023px) {
        margin-bottom: 14px;
      }
    }
  }
  .img-approach {
    position: relative;
    &__bg {
      position: absolute;
      bottom: 171px;
      width: calc(100% + 170px);
      max-width: calc(100% + 170px);
      left: -120px;
      @media screen and (max-width: 1023px) {
        left: 0;
        width: 100%;
        max-width: 100%;
        bottom: 42px;
      }
      @media screen and (max-width: 600px) {
        bottom: 0;
      }
    }

    &__center {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      @media screen and (max-width: 1023px) {
        width: 457px;
      }
    }
  }

  .bg-illustrations {
    background: #c8f2d6;
  }
  .illustrations {
    max-width: calc(1108px + 90px);
    width: 100%;
    margin: 0 auto;
    padding: 102px 45px 59px;
    @media screen and (max-width: 1023px) {
      padding: 57px 45px 36px;
    }
    @media screen and (max-width: 600px) {
      padding: 44px 20px;
    }
    &__row {
      display: flex;
      margin: 0 -14px 30px;
      @media screen and (max-width: 1023px) {
        margin: 0 -12px 24px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        margin: 0 0 0;
      }
      &_bottom {
        align-items: flex-end;
      }
      &_title {
        margin-bottom: 33px;
        margin-top: 135px;
        &:first-child {
          margin-top: 0;
        }
        @media screen and (max-width: 1023px) {
          margin-bottom: 3px;
          margin-top: 124px;
        }
        @media screen and (max-width: 600px) {
          margin-top: 27px;
          margin-bottom: 28px;
          display: block;
        }
      }
      &_reverse {
        .illustrations__col_left {
          width: 67%;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
        .illustrations__col_right {
          width: 33%;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }

    &__col {
      padding: 0 14px;
      @media screen and (max-width: 1023px) {
        padding: 0 12px;
      }
      @media screen and (max-width: 600px) {
        padding: 0;
        width: 100%;
      }
      &_left {
        width: 33%;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 12px;
        }
      }

      &_right {
        width: 67%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        .illustrations__title {
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0;
          text-transform: none;
          padding-top: 0;
          margin-bottom: 0px;
          @media screen and (max-width: 600px) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }

    &__title {
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #000000;
      padding-top: 4px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 23px;
      }
    }

    &__img {
      @media screen and (max-width: 600px) {
        transform: none !important;
      }
      img {
        display: block;
        margin-bottom: 30px;
        @media screen and (max-width: 1023px) {
          margin-bottom: 24px;
        }
        @media screen and (max-width: 600px) {
          margin: 0 0 12px;
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0px;
          @media screen and (max-width: 600px) {
            margin-bottom: 12px;
          }
        }
        &.illustrations-bottom {
          margin: 0 0 -73px 0;
          position: relative;
          right: -95px;
          @media screen and (max-width: 1023px) {
            right: -63px;
          }
          @media screen and (max-width: 600px) {
            right: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .section-video {
    padding-top: 89px;
    padding-bottom: 132px;
    @media screen and (max-width: 1023px) {
      padding-top: 59px;
      padding-bottom: 48px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

  .video {
    margin-bottom: 74px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 40px;
    }
    &-iframe {
      margin-bottom: 160px;
      @media screen and (max-width: 1023px) {
        margin-bottom: 117px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 46px;
      }
      .video__wrapper {
        padding-bottom: 50%;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-primary-color-case);
      text-align: left;
      margin-bottom: 32px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 28px;
      }
    }

    &__wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
      &::v-deep iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__poster {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: url("../../assets/img/cases/vacciland/video-poster-2.jpg") no-repeat center / 100% auto;
      background-size: cover;
      z-index: 2;
    }

    &__play-btn {
      position: absolute;
      z-index: 5;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }
  .cards {
    overflow: hidden;
    background: #146ab4;
    position: relative;
    padding-bottom: 50.8%;
    img {
      display: block;
      position: absolute;
      left: -5.2%;
      top: -39.8%;
      width: 113%;
      max-width: 113%;
      pointer-events: none;
      @media screen and (max-width: 600px) {
        left: -16.2%;
        top: -46.8%;
        width: 135%;
        max-width: 135%;
      }
    }
    @media screen and (max-width: 600px) {
      margin: 0 -20px;
      padding-bottom: 72.8%;
    }
  }

  .bg-landing {
    background: #b4d5f1;
    overflow: hidden;
  }
  .landing {
    max-width: calc(1108px + 90px);
    width: 100%;
    margin: 0 auto;
    padding: 133px 45px 41px;
    @media screen and (max-width: 1023px) {
      padding: 57px 45px 21px;
    }
    @media screen and (max-width: 600px) {
      padding: 45px 20px 8px;
    }
    img {
      margin-left: -21px;
      max-width: calc(100% + 21px);
      width: calc(100% + 21px);
      @media screen and (max-width: 768px) {
        margin-left: -18px;
        max-width: calc(100% + 69px);
        width: calc(100% + 69px);
      }
      @media screen and (max-width: 600px) {
        margin-left: -18px;
        max-width: calc(100% + 49px);
        width: calc(100% + 49px);
      }
      @media screen and (max-width: 425px) {
        margin-left: -18px;
        max-width: calc(100% + 39px);
        width: calc(100% + 39px);
      }
    }
  }
  .text-landing {
    margin-bottom: 55px;
    @media screen and (max-width: 600px) {
      margin-bottom: 35px;
    }
    .text-row__right-title {
      margin-bottom: 16px;
    }
    .text-row__right-title,
    .text-row__right-text,
    .text-row__left-text {
      color: #000000;
    }
  }
}
</style>
